p {
  & > .normal {
    font-size: 1em;
  }

  & > .button {
    width: fit-content;
    font-weight: 500 !important;
    min-height: 53px;
    padding: 0 24px;
    background-color: var(--primary);
    color: var(--white) !important;
    outline: none;
    transition: var(--transition);

    @media screen and (max-width: 1059px) {
      font-size: 0.875rem !important;
      line-height: 1.225em !important;
    }

    @media screen and (min-width: 1060px) {
      font-size: 1.125rem !important;
      line-height: 1.4em !important;
    }

    &:hover {
      background-color: var(--primary-dark);
      color: var(--white) !important;
    }

    & + .small {
      @media screen and (max-width: 375px) {
        margin: 24px 24px 0;
      }
      @media screen and (min-width: 376px) and (max-width: 1059px) {
        margin-left: 24px;
      }

      @media screen and (min-width: 1060px) {
        margin-left: 32px;
      }
    }
  }

  & > .small {
    @media screen and (max-width: 1059px) {
      font-size: 0.875rem !important;
      font-weight: 400;
      line-height: 1.225em !important;
    }

    @media screen and (min-width: 1060px) {
      font-size: 1.125rem !important;
      font-weight: 400;
      line-height: 1.4em !important;
    }
  }
}
